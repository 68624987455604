<template>
  <div id="loading-bg">
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    handleLoginEvent (data) {
      this.$store.dispatch('updateUserInfo', {
        displayName: data.profile.name,
        email: data.profile.email,
        photoURL: data.profile.picture,
        providerId: data.profile.sub.substr(0, data.profile.sub.indexOf('|')),
        userID: {...data.profile[`${process.env.VUE_APP_API_URL}/app_metadata`]},
        userRole: data.profile[`${process.env.VUE_APP_API_URL}/role`][0],
        uid: data.profile.sub
      })
      this.$acl.change(this.$store.state.AppActiveUser.userRole)
      if (this.$store.state.AppActiveUser.userRole === 'myshipblu-customer') {
        this.$router.push(`/${this.$cookies.get('locale')}/home`).catch(() => {})
      } else {
        this.$router.push(`/${this.$cookies.get('locale')}/operations/dashboard`).catch(() => {})
      }
    }
  },
  created () {
    if (!this.$cookies.get('language')) {
      this.$cookies.set('language', 'ar', '100y')
    }
    this.$auth.handleAuthentication()
  }
}

</script>

